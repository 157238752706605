<template>
  <div>
    <div class="header-container">
      <div>
        <v-breadcrumbs>
          <v-breadcrumbs-item class="breadcrumbs-hover-default">
            <i class="fal fa-alarm-clock" style="margin:0 10px 0 0;font-size: 20px"></i>
            <span @click="main()">{{ $t("scheduleDetail.title") }}</span>
          </v-breadcrumbs-item>

          <v-breadcrumbs-item class="breadcrumbs-hover" v-if="$route.name === 'scheduleDetail.create'">
            <span v-show="true">/</span>
            <span>{{ $t("scheduleDetail.create") }}</span>
          </v-breadcrumbs-item>

          <v-breadcrumbs-item class="breadcrumbs-hover" v-if="$route.name === 'scheduleDetail.edit'">
            <span v-show="true">/</span>
            <span>Edit CheckInSchedule</span>
          </v-breadcrumbs-item>

          <v-breadcrumbs-item class="breadcrumbs-hover" v-if="$route.name === 'checkScan.index'">
            <span v-show="true">/</span>
            <span>{{ $t("schedule_by_week_day.title") }}</span>
          </v-breadcrumbs-item>
        </v-breadcrumbs>
      </div>
    </div>

    <div class="page-content">
      <div class="content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },

  methods: {
    main() {
      this.$router
        .push({
          name: "scheduleDetail.index",
        })
        .catch(() => { });
    },
  },
};
</script>

<style scoped lang="scss"></style>
